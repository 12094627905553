.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.backgroundImage{
  position:fixed;
  top: -100vw;
  left: -100vw;
  right:-100vw;
  bottom:-100vw;
  /* width: 100%; */
  /* height: 100%; */
  background-image: url("/public/bitmap.png");
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotate-background 100s linear infinite;
}

.body{
  margin:0;
  height: 100%;
  overflow:hidden;
  background-color: black;
  color: white;
}

.content{
  color: white;
  position: absolute;
  top: 15%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-color: rgba(0,0,0, 0.3);
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.content_contact{
  color: white;
  position: absolute;
  top: 15%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-color: rgba(0,0,0, 0.3);
  text-align: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  overflow: auto;
}

.content_projects{
  color: white;
  position:absolute;
  top: 15%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-color: rgba(0,0,0, 0.3);
  text-align: center;
  font-size: calc(10px + 2vmin);
  overflow: auto;
}

.content_gallery{
  color: white;
  position: absolute;
  top: 15%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-color: rgba(0,0,0, 0.3);
  text-align: center;
  font-size: calc(10px + 2vmin);
  overflow: auto;
}

.resumeImage{  
  max-width: 100%;
  max-height: 100%;
}

@keyframes rotate-background {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gallery {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 10px;
}

@media screen and (max-width: 767px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }
}

.gallery_item {
  width: 100%;
  height: 100%;
  object-fit:contain;
  transition: transform 0.2s ease-in-out;
}


.photoImage{  
  max-width: 40%;
  max-height: 40%;
}

.projects{
  font-family: 'Courier New', Courier, monospace;
  position: relative;
  text-align:left;
  margin: auto;
  table-layout: auto;
  padding-right: 3%;
}

.projectName{
  font-size: calc(5px + 2vmin);
}

.projectsDesc{
  text-align: justify;
  font-size: calc(3px + 2vmin);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.projects_icon{
  width: 10vw;
  height: 10vh;

}

.project_header{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  font-size: calc(5px + 2vmin);
  text-align:left;
}


.contact_table{
  position: relative;
  text-align: left;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;

}

.contact_icons{
  height:5vh;
  display: inline;
}

.name{
  font-size: calc(13px + 2vmin);
  font-family: Arial, Helvetica, sans-serif;
}

.roles{
  font-family:Georgia, 'Times New Roman', Times, serif;
}

.navlink{
  color: white;
  text-decoration: none;
  text-align: center;
}

.navlink_active{
  color: white;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}

.top_nav_bar{
  width: 100%;
  font-size: calc(10px + 2vmin);
  background-color: (0,0,0,1.0);
  position: fixed;
  height: 10vh;
  vertical-align: middle;
  text-align: center;
  margin: auto;
  table-layout: fixed;
}
